import { ClientTimestamp } from '../shared-types';

export enum AssetFileSize {
  original = 'ORIGINAL',
  fprint = 'FPRINT',
  videoStream = 'VIDEO_STREAM',
  size100 = '100x100',
  size200 = '200x200',
  size300 = '300x300',
  size1000 = '1000x1000',
  size300Watermark = '300x300_WATERMARK',
  size1000Watermark = '1000x1000_WATERMARK',
}

export interface AssetFile {
  size: AssetFileSize;
  fileName: string;
  filePath: string;
  creationDate?: ClientTimestamp;
}
