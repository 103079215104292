import { AssetFile, CustomFieldData } from '../shared';
import { ClientTimestamp } from '../shared-types';
import { ModelConsent } from './model-consent.model';

export enum ProjectStatus {
  open = 'OPEN',
  closed = 'CLOSED',
}

export interface ProjectExport {
  type: string;
  externalId: string;
  exportDate: ClientTimestamp;
  userId?: string;
}

export interface Project {
  id: string;
  name: string;
  description?: string;
  instructions?: string;
  banner?: AssetFile[];
  status: ProjectStatus;
  formTemplateIds: string[];
  modelConsent?: ModelConsent[]; //Deprecated
  modelIds: string[];
  assignees: string[];
  shootDate?: ClientTimestamp;
  expirationDate?: ClientTimestamp;
  createdBy: string;
  creationDate: ClientTimestamp;
  modificationDate?: ClientTimestamp;
  deleted?: boolean;
  online?: boolean;
  enableRevoke?: boolean;
  resizeImages?: boolean;
  customData?: CustomFieldData[];
  allowMinors?: boolean;
  assetApprovalPeriod?: boolean;
  assetApprovalEndDate?: ClientTimestamp;
  exports?: ProjectExport[];
}
