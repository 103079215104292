import { ModelConsentStatus } from '../consent';
import { ModelTag } from '../consent/model-tag.model';
import { CustomFieldData } from '../shared';
import { ClientTimestamp } from '../shared-types';
import { AssetFile } from '../shared/asset-file.model';
import { AssetFace, AssetFaceSuggestion } from './asset-face.model';

export enum AssetStatus {
  uploading = 'UPLOADING',
  uploaded = 'UPLOADED',
  verified = 'VERIFIED',
}

export interface AssetExport {
  type: string;
  externalId: string;
  exportDate: ClientTimestamp;
  userId?: string;
}

export interface AssetDownload {
  downloadDate: ClientTimestamp;
  userId?: string;
}

export interface AssetMatch {
  page_url: string;
  image_url: string;
  date: string;
  print: { id: string; certain: number; verified: boolean };
}

export interface AssetImageMetadata {
  type: 'exif' | 'iptc' | 'xmp';
  name: string;
  value: string;
}

export interface AssetColor {
  score: number;
  red: number;
  green: number;
  blue: number;
}

export interface AssetSuggestedTag {
  score: number;
  name: string;
}

export interface AssetFocalPoint {
  x: number;
  y: number;
}

export interface Asset {
  id: string;
  projectId: string;
  name: string;
  size: number;
  width?: number;
  height?: number;
  type: string;
  status: AssetStatus;
  files: AssetFile[];
  modelTags?: ModelTag[];
  modelIds?: string[];
  modelDeclines?: string[];
  fingerprintId?: string;
  fingerprintError?: string;
  createdBy: string;
  creationDate: ClientTimestamp;
  modificationDate: ClientTimestamp;
  deleted?: boolean;
  exports?: AssetExport[];
  downloads?: AssetDownload[];
  matches?: AssetMatch[];
  online?: boolean;
  modelConsentStatus?: ModelConsentStatus;
  faces?: AssetFace[];
  suggestions?: AssetFaceSuggestion[];
  groupId?: string;
  dpi?: number;
  imageMetadata?: AssetImageMetadata[];
  colors?: AssetColor[];
  suggestedTags?: AssetSuggestedTag[];
  tagIds?: string[];
  focalPoint?: AssetFocalPoint;
  triggerUpdate?: ClientTimestamp;
  customData?: CustomFieldData[];
  watermark?: boolean;
  blockForDownload?: boolean;
}
