import { map, Observable, of, switchMap, take } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import {
  arrayRemove,
  arrayUnion,
  documentId,
  orderBy,
  Timestamp,
  where,
} from '@angular/fire/firestore';
import {
  CollectionName,
  FunctionName,
  StoragePath,
} from '@verify/shared-components/helpers';
import {
  Asset,
  AssetFace,
  AssetStatus,
  ClientTimestamp,
  DeleteItemsRequest,
  DownloadZipRequest,
  DownloadZipResponse,
  FaceAiFaceSuggestionsRequest,
  FaceAiFaceSuggestionsResponse,
  FaceAiTagAssetsRequest,
  IndexName,
  ModelTag,
  SearchRequest,
  SearchRequestFilter,
  SearchRequestOrderBy,
  SearchResponse,
  SendImagesToBynderRequest,
  SendImagesToBynderResponse,
  SendImagesToLythoRequest,
  SendImagesToLythoResponse,
  Tag,
} from '@verify/shared-components/models';
import {
  AuthService,
  BatchActionType,
  FirestoreService,
  FunctionService,
  StorageService,
} from '@verify/shared-components/services';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private firestore = inject(FirestoreService);
  private storageService = inject(StorageService);
  private functionService = inject(FunctionService);
  private authService = inject(AuthService);

  constructor() {}

  getAsset(assetId: string): Observable<Asset | undefined> {
    return this.firestore.getDocument<Asset>(
      `${CollectionName.assets}/${assetId}`,
    );
  }

  getAssets({
    projectId,
    modelId,
    order,
  }: {
    projectId?: string;
    modelId?: string;
    order?: { field: string; direction: 'asc' | 'desc' };
  }): Observable<Asset[]> {
    const filters = [
      where('deleted', '!=', true),
      ...(projectId ? [where('projectId', '==', projectId)] : []),
      ...(modelId ? [where('modelIds', 'array-contains', modelId)] : []),
      ...(order
        ? [orderBy(order.field, order.direction)]
        : [orderBy('creationDate', 'asc')]),
    ];
    return this.firestore.getQuery<Asset>(CollectionName.assets, ...filters);
  }

  getAssetsById(assetIds: string[]): Observable<Asset[]> {
    return this.firestore.getQuery<Asset>(
      CollectionName.assets,
      where(documentId(), 'in', assetIds),
    );
  }

  searchAssets({
    queryString,
    filter,
    from,
    size,
    orderBy,
  }: {
    queryString: string;
    filter?: SearchRequestFilter | SearchRequestFilter[];
    from?: number;
    size?: number;
    orderBy?: SearchRequestOrderBy;
  }): Observable<{ totalHits: number; assets: Asset[] }> {
    return this.functionService
      .call<SearchRequest, SearchResponse<Asset>>(FunctionName.elasticSearch, {
        index: IndexName.asset,
        query: {
          queryString,
          searchFields: ['name', 'type'],
        },
        filter,
        from,
        size,
        orderBys: orderBy ? [orderBy] : [],
        filterDeleted: true,
      })
      .pipe(
        switchMap((response) =>
          response.hits?.length
            ? this.getAssetsById(response.hits.map((hit) => hit.id)).pipe(
                map((assets) => ({
                  totalHits: response.totalHits,
                  assets: response.hits
                    .map((hit) => assets.find((asset) => asset.id === hit.id))
                    .filter((asset) => !!asset),
                })),
              )
            : of({ totalHits: 0, assets: [] }),
        ),
      );
  }

  searchAssetsKnn({
    queryString,
    queryImage,
    filter,
    from,
    size,
    orderBy,
  }: {
    queryString?: string;
    queryImage?: string;
    filter?: SearchRequestFilter | SearchRequestFilter[];
    from?: number;
    size?: number;
    orderBy?: SearchRequestOrderBy;
  }): Observable<{ totalHits: number; assets: Asset[] }> {
    return this.functionService
      .call<SearchRequest, SearchResponse<Asset>>(FunctionName.elasticSearch, {
        index: IndexName.asset,
        query: {
          queryString,
          queryImage,
          searchFields: ['assetVector'],
        },
        filter,
        from,
        size,
        orderBys: orderBy ? [orderBy] : [],
        filterDeleted: true,
        knn: true,
      })
      .pipe(
        switchMap((response) =>
          response.hits?.length
            ? this.getAssetsById(response.hits.map((hit) => hit.id)).pipe(
                map((assets) => ({
                  totalHits: response.totalHits,
                  assets: response.hits
                    .map((hit) => assets.find((asset) => asset.id === hit.id))
                    .filter((asset) => !!asset),
                })),
              )
            : of({ totalHits: 0, assets: [] }),
        ),
      );
  }

  addAsset(
    projectId: string,
    asset: Partial<Asset>,
    file: File,
    groupId?: string,
  ): Observable<Asset | undefined> {
    const asset$ = this.firestore.addDocument<Asset>(
      `${CollectionName.assets}`,
      {
        ...asset,
        projectId,
        fingerprintId: null,
        status: AssetStatus.uploading,
        name: file.name,
        size: file.size,
        type: file.type,
        createdBy: this.authService.currentUser.id,
        creationDate: Timestamp.now(),
        modificationDate: Timestamp.now(),
        deleted: false,
        online: false,
        modelConsentStatus: null,
        groupId: groupId || null,
      },
    );

    asset$.pipe(take(1)).subscribe((asset) => {
      if (asset) {
        this.uploadFile(file, projectId, asset.id);
      }
    });

    return asset$;
  }

  addTag(assetIds: string[], tag: Tag): Observable<void> {
    return this.firestore.batchWrite(
      assetIds.map((assetId) => ({
        documentPath: `${CollectionName.assets}/${assetId}`,
        data: { modificationDate: Timestamp.now(), tagIds: arrayUnion(tag.id) },
        type: BatchActionType.update,
      })),
    );
  }

  removeTag(assetIds: string[], tag: Tag): Observable<void> {
    return this.firestore.batchWrite(
      assetIds.map((assetId) => ({
        documentPath: `${CollectionName.assets}/${assetId}`,
        data: {
          modificationDate: Timestamp.now(),
          tagIds: arrayRemove(tag.id),
        },
        type: BatchActionType.update,
      })),
    );
  }

  addCustomData(
    assetIds: string[],
    name: string,
    value: string | number | ClientTimestamp,
  ): void {
    assetIds.forEach((assetId) => {
      this.getAsset(assetId)
        .pipe(take(1))
        .subscribe((asset) => {
          this.firestore.updateDocument<Asset>(
            `${CollectionName.assets}/${assetId}`,
            {
              modificationDate: Timestamp.now(),
              customData: [
                ...(asset.customData || []).filter(
                  (data) => data.name !== name,
                ),
                {
                  name,
                  value,
                },
              ],
            },
          );
        });
    });
  }

  removeCustomData(assetIds: string[], name: string): void {
    assetIds.forEach((assetId) => {
      this.getAsset(assetId)
        .pipe(take(1))
        .subscribe((asset) => {
          this.firestore.updateDocument<Asset>(
            `${CollectionName.assets}/${assetId}`,
            {
              modificationDate: Timestamp.now(),
              customData: [
                ...(asset.customData || []).filter(
                  (data) => data.name !== name,
                ),
              ],
            },
          );
        });
    });
  }

  addModelTag(assetId: string, modelTag: ModelTag): void {
    this.getAsset(assetId)
      .pipe(take(1))
      .subscribe((asset) => {
        if (!asset.modelIds?.includes(modelTag.modelId)) {
          this.firestore.updateDocument<Asset>(
            `${CollectionName.assets}/${assetId}`,
            {
              modelTags: arrayUnion(modelTag),
              modelIds: arrayUnion(modelTag.modelId),
              modificationDate: Timestamp.now(),
            },
          );
        }
      });
  }

  removeModelTag(assetId: string, modelTag: ModelTag): void {
    this.getAsset(assetId)
      .pipe(take(1))
      .subscribe((asset) => {
        if (asset.modelIds?.includes(modelTag.modelId)) {
          this.firestore.updateDocument<Asset>(
            `${CollectionName.assets}/${assetId}`,
            {
              modelTags: arrayRemove(
                asset.modelTags.find(
                  (assetModelTag) => assetModelTag.modelId === modelTag.modelId,
                ),
              ),
              modelIds: arrayRemove(modelTag.modelId),
              modificationDate: Timestamp.now(),
            },
          );
        }
      });
  }

  deleteAsset(assetId: string): void {
    this.firestore.updateDocument<Asset>(
      `${CollectionName.assets}/${assetId}`,
      {
        deleted: true,
        modificationDate: Timestamp.now(),
      },
    );
  }

  undoDeleteAsset(assetId: string): void {
    this.firestore.updateDocument<Asset>(
      `${CollectionName.assets}/${assetId}`,
      {
        deleted: false,
        modificationDate: Timestamp.now(),
      },
    );
  }

  verifyAsset(assetId: string, verified: boolean): void {
    this.firestore.updateDocument<Asset>(
      `${CollectionName.assets}/${assetId}`,
      {
        status: verified ? AssetStatus.verified : AssetStatus.uploaded,
        modificationDate: Timestamp.now(),
      },
    );
  }

  permanentlyDeleteAssets(assetIds: string[]): Observable<void> {
    return this.functionService.call<DeleteItemsRequest, void>(
      FunctionName.deleteItems,
      { itemIds: assetIds, type: 'assets' },
      { timeout: 600000 },
    );
  }

  getDeletedAssets(): Observable<Asset[]> {
    const filters = [
      where('deleted', '==', true),
      orderBy('modificationDate', 'desc'),
    ];
    return this.firestore.getQuery<Asset>(CollectionName.assets, ...filters);
  }

  autoTagModels(projectId: string, assetIds: string[]): Observable<number> {
    return this.functionService.call<FaceAiTagAssetsRequest, number>(
      FunctionName.faceAiTagAssets,
      { projectId, assetIds },
      { timeout: 600000 },
    );
  }

  getSuggestions(
    projectId: string,
    face: AssetFace,
  ): Observable<FaceAiFaceSuggestionsResponse> {
    return this.functionService.call<
      FaceAiFaceSuggestionsRequest,
      FaceAiFaceSuggestionsResponse
    >(FunctionName.getSuggestions, { projectId, face });
  }

  exportAssetsToLytho(
    projectId: string,
    assetIds: string[],
  ): Observable<SendImagesToLythoResponse> {
    return this.functionService.call<
      SendImagesToLythoRequest,
      SendImagesToLythoResponse
    >(
      FunctionName.sendImagesToLytho,
      { projectId, assetIds },
      { timeout: 600000 },
    );
  }

  exportAssetsToBynder(
    projectId: string,
    assetIds: string[],
  ): Observable<SendImagesToBynderResponse> {
    return this.functionService.call<
      SendImagesToBynderRequest,
      SendImagesToBynderResponse
    >(
      FunctionName.sendImagesToBynder,
      { projectId, assetIds },
      { timeout: 600000 },
    );
  }

  downloadAssets(assetIds: string[]): Observable<DownloadZipResponse> {
    this.firestore.batchWrite(
      assetIds.map((assetId) => ({
        documentPath: `${CollectionName.assets}/${assetId}`,
        data: {
          downloads: arrayUnion({
            downloadDate: Timestamp.now(),
            userId: this.authService.currentUser.id,
          }),
        },
        type: BatchActionType.update,
      })),
    );

    const response = this.functionService.call<
      DownloadZipRequest,
      DownloadZipResponse
    >(
      FunctionName.downloadZip,
      { projectId: '', assetIds },
      { timeout: 600000 },
    );

    response.subscribe((response) => {
      this.storageService
        .getDownloadUrlFromPath(response.downloadUrl)
        .subscribe((url) => {
          window.open(url);
        });
    });
    return response;
  }

  updateAssetMatches(assetId: string): Observable<void> {
    return this.functionService.call<{ assetId: string }, void>(
      FunctionName.callMonitoringResults,
      { assetId },
    );
  }

  getNextAsset(projectId: string, assetId: string): Observable<Asset> {
    return this.getAssets({ projectId }).pipe(
      map((assets) => {
        const index = assets.findIndex((asset) => asset.id === assetId);
        return assets[index + 1 < assets.length ? index + 1 : 0];
      }),
    );
  }

  getPreviousAsset(projectId: string, assetId: string): Observable<Asset> {
    return this.getAssets({ projectId }).pipe(
      map((assets) => {
        const index = assets.findIndex((asset) => asset.id === assetId);
        return assets[index > 0 ? index - 1 : assets.length - 1];
      }),
    );
  }

  private uploadFile(file: File, projectId: string, assetId: string): void {
    this.storageService.uploadFile(
      `${StoragePath.projects}/${projectId}/${StoragePath.assets}/${assetId}/${file.name}`,
      file,
      assetId,
    );
  }
}
