import { inject, Injectable } from '@angular/core';
import { arrayRemove, arrayUnion } from '@angular/fire/firestore';
import { UploadTask } from '@angular/fire/storage';
import { FunctionName, StoragePath } from '@verify/shared-components/helpers';
import { CustomField, Tenant, Usage } from '@verify/shared-components/models';
import {
  AuthService,
  BatchActionType,
  FirestoreService,
  FunctionService,
  StorageService,
} from '@verify/shared-components/services';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private firestore = inject(FirestoreService);
  private authService = inject(AuthService);
  private storageService = inject(StorageService);
  private functionService = inject(FunctionService);

  constructor() {}

  updateTenant(tenant: Partial<Tenant>): Observable<void> {
    return from(this.firestore.updateDocument<Tenant>(``, tenant));
  }

  updateLogo(file: File): UploadTask {
    return this.storageService.uploadFile(
      `${StoragePath.logo}/${file.name}`,
      file,
      'logo',
    );
  }

  updateLogoSmall(file: File): UploadTask {
    return this.storageService.uploadFile(
      `${StoragePath.logoSmall}/${file.name}`,
      file,
      'logo-small',
    );
  }

  updateLoginBackground(file: File): UploadTask {
    return this.storageService.uploadFile(
      `${StoragePath.loginBackground}/${file.name}`,
      file,
      'loginBackground',
    );
  }

  addCustomModelField(customField: CustomField): void {
    this.firestore.updateDocument<Tenant>('', {
      customModelFields: arrayUnion(customField),
    });
  }

  editCustomModelField(oldField: CustomField, updatedField: CustomField): void {
    this.firestore.batchWrite([
      {
        documentPath: '',
        data: { customModelFields: arrayRemove(oldField) },
        type: BatchActionType.update,
      },
      {
        documentPath: '',
        data: { customModelFields: arrayUnion(updatedField) },
        type: BatchActionType.update,
      },
    ]);
  }

  removeCustomModelField(customField: CustomField): void {
    this.firestore.updateDocument<Tenant>('', {
      customModelFields: arrayRemove(customField),
    });
  }

  addCustomProjectField(customField: CustomField): void {
    this.firestore.updateDocument<Tenant>('', {
      customProjectFields: arrayUnion(customField),
    });
  }

  editCustomProjectField(
    oldField: CustomField,
    updatedField: CustomField,
  ): void {
    this.firestore.batchWrite([
      {
        documentPath: '',
        data: { customProjectFields: arrayRemove(oldField) },
        type: BatchActionType.update,
      },
      {
        documentPath: '',
        data: { customProjectFields: arrayUnion(updatedField) },
        type: BatchActionType.update,
      },
    ]);
  }

  removeCustomProjectField(customField: CustomField): void {
    this.firestore.updateDocument<Tenant>('', {
      customProjectFields: arrayRemove(customField),
    });
  }

  addCustomAssetField(customField: CustomField): void {
    this.firestore.updateDocument<Tenant>('', {
      customAssetFields: arrayUnion(customField),
    });
  }

  editCustomAssetField(oldField: CustomField, updatedField: CustomField): void {
    this.firestore.batchWrite([
      {
        documentPath: '',
        data: { customAssetFields: arrayRemove(oldField) },
        type: BatchActionType.update,
      },
      {
        documentPath: '',
        data: { customAssetFields: arrayUnion(updatedField) },
        type: BatchActionType.update,
      },
    ]);
  }

  removeCustomAssetField(customField: CustomField): void {
    this.firestore.updateDocument<Tenant>('', {
      customAssetFields: arrayRemove(customField),
    });
  }

  getFprintUsage(): Observable<Usage[]> {
    return this.functionService.call<{ token: string }, Usage[]>(
      FunctionName.fPrintSelectUsage,
      {
        token: this.authService.tenant.fprintConfig.apiKey,
      },
    );
  }
}
