import { inject, Pipe, PipeTransform } from '@angular/core';
import { AssetFile, AssetFileSize } from '@verify/shared-components/models';
import { Observable, of } from 'rxjs';
import { StorageService } from '../storage.service';

/**
 * Returns a url for given AssetFile in given format if it exists
 *
 * Usage:
 *  'asset-url' | hasRule
 */
@Pipe({
  name: 'assetUrl',
  standalone: true,
})
export class AssetUrlPipe implements PipeTransform {
  private storageService: StorageService = inject(StorageService);

  transform(files: AssetFile[], size: AssetFileSize): Observable<string> {
    const fileFormat = files?.find((file) => file.size === size) || files?.[0];
    if (fileFormat) {
      return this.storageService.getDownloadUrl(fileFormat);
    }
    return of('');
  }
}
