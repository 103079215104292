import { from, map, Observable, switchMap } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import {
  addDoc,
  collection,
  collectionChanges,
  collectionData,
  collectionGroup,
  CollectionReference,
  deleteDoc,
  doc,
  docData,
  DocumentChange,
  DocumentReference,
  endAt,
  Firestore,
  getCountFromServer,
  orderBy,
  Query,
  query,
  QueryCompositeFilterConstraint,
  QueryConstraint,
  QueryNonFilterConstraint,
  startAt,
  UpdateData,
  updateDoc,
  WithFieldValue,
  writeBatch,
} from '@angular/fire/firestore';
import { typedConverter } from '@verify/shared-components/helpers';

interface DocumentData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

export enum BatchActionType {
  add = 'add',
  update = 'update',
  delete = 'delete',
}

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  private firestore: Firestore = inject(Firestore);
  private auth: Auth = inject(Auth);

  private static tenantCollection = 'tenants';

  constructor() {}

  getCollection<T extends DocumentData>(
    collectionPath: string,
  ): Observable<T[]> {
    const collectionRef = this.getCollectionRef<T>(collectionPath);
    return collectionData(collectionRef, { idField: 'id' });
  }

  getCollectionChanges<T extends DocumentData>(
    collectionPath: string,
  ): Observable<DocumentChange<T, DocumentData>[]> {
    const collectionRef = this.getCollectionRef<T>(collectionPath);
    return collectionChanges(collectionRef);
  }

  getCollectionGroup<T extends DocumentData>(
    collectionId: string,
  ): Observable<T[]> {
    const collectionRef =
      this.getCollectionGroupQuery<T>(collectionId).withConverter(
        typedConverter<T>(),
      );
    return collectionData(collectionRef, { idField: 'id' });
  }

  getDocument<T extends DocumentData>(
    documentPath: string,
  ): Observable<T | undefined> {
    const docRef =
      this.getDocRef(documentPath).withConverter(typedConverter<T>());
    return docData(docRef, { idField: 'id' });
  }

  getQuery<T extends DocumentData>(
    collectionPath: string,
    ...queryConstraints: QueryConstraint[]
  ): Observable<T[]>;
  getQuery<T extends DocumentData>(
    collectionPath: string,
    compositeFilter: QueryCompositeFilterConstraint,
    ...queryConstraints: QueryNonFilterConstraint[]
  ): Observable<T[]>;
  getQuery<T extends DocumentData>(
    collectionPath: string,
    ...p2: unknown[]
  ): Observable<T[]> {
    const collectionRef = this.getCollectionRef<T>(collectionPath);
    const queryRef =
      p2 instanceof QueryCompositeFilterConstraint
        ? query(
            collectionRef,
            p2,
            ...(p2.slice(1) as QueryNonFilterConstraint[]),
          )
        : query(collectionRef, ...(p2 as QueryConstraint[]));
    return collectionData(queryRef, { idField: 'id' });
  }

  getCount<T extends DocumentData>(
    collectionPath: string,
    ...queryConstraints: QueryConstraint[]
  ): Observable<number> {
    const collectionRef = this.getCollectionRef<T>(collectionPath);
    const queryRef = query(collectionRef, ...queryConstraints);
    return from(getCountFromServer(queryRef)).pipe(
      map((value) => value.data().count),
    );
  }

  getGroupQuery<T extends DocumentData>(
    collectionId: string,
    queryConstraints: QueryConstraint[],
  ): Observable<T[]> {
    const collectionRef =
      this.getCollectionGroupQuery<T>(collectionId).withConverter(
        typedConverter<T>(),
      );
    const queryRef = query(collectionRef, ...queryConstraints);
    return collectionData(queryRef, { idField: 'id' });
  }

  addDocument<T extends DocumentData>(
    collectionPath: string,
    document: T | Partial<T>,
  ): Observable<T | undefined> {
    const collectionRef = this.getCollectionRef<T>(collectionPath);
    return from(addDoc(collectionRef, document as WithFieldValue<T>)).pipe(
      switchMap((docRef) => docData(docRef, { idField: 'id' })),
    );
  }

  deleteDocument<T extends DocumentData>(
    documentPath: string,
  ): Observable<void> {
    const docRef =
      this.getDocRef(documentPath).withConverter(typedConverter<T>());
    return from(deleteDoc(docRef));
  }

  updateDocument<T extends DocumentData>(
    documentPath: string,
    data: UpdateData<T>,
  ): Observable<void> {
    const docRef =
      this.getDocRef(documentPath).withConverter(typedConverter<T>());
    return from(updateDoc(docRef, data));
  }

  batchWrite(
    actions: Array<{
      documentPath: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data?: UpdateData<any>;
      type: BatchActionType;
    }>,
  ): Observable<void> {
    const batch = writeBatch(this.firestore);
    actions.forEach(({ documentPath, data, type }) => {
      const docRef = this.getDocRef(documentPath);
      switch (type) {
        case 'add': {
          batch.set(docRef, data);
          break;
        }
        case 'update': {
          batch.update(docRef, data);
          break;
        }
        case 'delete': {
          batch.delete(docRef);
          break;
        }
      }
    });
    return from(batch.commit());
  }

  // getCollectionData(): void {
  //   query(
  //     collection(this.firestore, 'name'),
  //     where('capital', '==', true),
  //     orderBy('test', 'asc'),
  //   );
  //   collectionChanges();
  //   sortedChanges()
  //   and()
  // }

  getCollectionRef<T extends DocumentData>(
    collectionName: string,
  ): CollectionReference<T, T> {
    return collection(
      this.firestore,
      this.getTenantPath(),
      collectionName,
    ).withConverter(typedConverter<T>());
  }

  getDocRef<T extends DocumentData>(
    documentPath: string,
  ): DocumentReference<T, T> {
    return doc(
      this.firestore,
      this.getTenantPath(),
      documentPath,
    ).withConverter(typedConverter<T>());
  }

  private getCollectionGroupQuery<T extends DocumentData>(
    collectionId: string,
  ): Query<DocumentData, DocumentData> {
    const group = collectionGroup(this.firestore, collectionId).withConverter(
      typedConverter<T>(),
    );
    const tenantDoc = this.getDocRef('');
    return query(
      group,
      orderBy('__name__'),
      startAt(tenantDoc.path),
      endAt(`${tenantDoc.path}\uf8ff`),
    );
  }

  private getTenantPath(): string {
    return `${FirestoreService.tenantCollection}/${this.auth.tenantId}`;
  }

  private getTenantDocRef(): DocumentReference {
    return doc(
      this.firestore,
      `${FirestoreService.tenantCollection}/${this.auth.tenantId}`,
    );
  }
}
