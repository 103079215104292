@let backgroundUrl =
  tenantInfo.loginBackground | assetUrl: AssetFileSize.original | async;
<img
  class="background-image"
  [src]="backgroundUrl ? backgroundUrl : 'assets/login-background.jpg'"
/>
<div class="login">
  @let logoUrl = tenantInfo.logo | assetUrl: AssetFileSize.size300 | async;
  <div class="login-logo-container">
    <img
      class="login-logo"
      [src]="logoUrl ? logoUrl : 'assets/verify-logo-white.png'"
    />
  </div>
  @switch (view) {
    @case ("SIGNIN_OPTIONS") {
      <button
        mat-flat-button
        class="login-option login-option-email"
        color="primary"
        (click)="onChooseEmailLogin()"
      >
        <mat-icon>mail</mat-icon>Sign in with email
      </button>
      @for (provider of identityProviders; track $index) {
        @switch (provider.type) {
          @case ("microsoft") {
            <button
              mat-flat-button
              class="login-option login-option-microsoft"
              color="primary"
              (click)="onChooseMicrosoftLogin(provider)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
              >
                <rect x="1" y="1" width="9" height="9" fill="#f25022" />
                <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
                <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
                <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
              </svg>
              Sign in with Microsoft
            </button>
          }
        }
      }
    }
    @case ("EMAIL") {
      <form onsubmit="return false">
        <h6 class="login-header">Sign in with email</h6>
        <div class="login-form" [formGroup]="form">
          <mat-form-field class="login-form-field">
            <mat-label>Email</mat-label>
            <input
              matInput
              type="email"
              name="email"
              autocomplete="username"
              formControlName="email"
              autofocus
            />
            @if (form.get("email").errors?.["doesNotExist"]) {
              <mat-error>No account exists for this email address</mat-error>
            }
          </mat-form-field>

          <mat-form-field class="login-form-field" *ngIf="emailVerified">
            <mat-label>Password</mat-label>
            <input
              #passwordInput
              matInput
              type="password"
              name="password"
              autocomplete="current-password"
              formControlName="password"
              autofocus
            />
            @if (loginError === "INVALID_PASSWORD") {
              <mat-error
                >The email and password you entered don't match</mat-error
              >
            }
          </mat-form-field>

          <div class="login-form-buttons">
            <button
              *ngIf="!emailVerified"
              mat-flat-button
              type="submit"
              color="primary"
              (click)="onVerifyEmail()"
              [disabled]="form.get('email').invalid"
            >
              NEXT
            </button>
            @if (emailVerified) {
              <a
                class="text-caption"
                href="javascript:void(0)"
                (click)="onForgotPassword()"
                >Forgot password?</a
              >
              <button
                mat-flat-button
                type="submit"
                color="primary"
                (click)="onUsernamePasswordLogin()"
                [disabled]="form.invalid"
              >
                SIGN IN
              </button>
            }
          </div>
        </div>
      </form>
    }
    @case ("PASSWORD_FORGET") {
      <form onsubmit="return false">
        <h6 class="login-header">Recover password</h6>
        <p>
          Get instructions sent to this email that explain how to reset your
          password
        </p>
        <div class="login-form" [formGroup]="form">
          <mat-form-field class="login-form-field">
            <mat-label>Email</mat-label>
            <input
              matInput
              type="email"
              autocomplete="username"
              formControlName="email"
              autofocus
            />
            @if (form.get("email").invalid || loginError === "INVALID_EMAIL") {
              <mat-error>Not a valid email</mat-error>
            }
          </mat-form-field>

          <div class="login-form-buttons">
            <button
              *ngIf="emailVerified"
              mat-button
              color="primary"
              (click)="onCancelPasswordReset()"
            >
              CANCEL
            </button>
            <button
              mat-flat-button
              color="primary"
              (click)="onSendPasswordReset()"
              [disabled]="form.get('email').invalid"
            >
              SEND
            </button>
          </div>
        </div>
      </form>
    }
    @case ("PASSWORD_RESET") {
      <form onsubmit="return false">
        @if (loginError === "RESET_EXPIRED") {
          <h6 class="login-header">Try resetting your password again</h6>
          <p>
            Your request to reset your password has expired or the link has
            already been used
          </p>
          <div class="login-form-buttons">
            <button
              mat-flat-button
              color="primary"
              (click)="onCancelPasswordReset()"
            >
              CONTINUE
            </button>
          </div>
        } @else {
          <h6 class="login-header">Reset your password</h6>
          <p>
            for <b>{{ form.get("email").value }}</b>
          </p>
          <div class="login-form" [formGroup]="form">
            <mat-form-field class="login-form-field">
              <mat-label>New password</mat-label>
              <input
                #passwordInput
                matInput
                type="password"
                formControlName="password"
                autofocus
              />
            </mat-form-field>

            <div class="login-form-buttons">
              <button
                mat-flat-button
                color="primary"
                (click)="onConfirmPasswordReset()"
                [disabled]="form.get('password').invalid"
              >
                SAVE
              </button>
            </div>
          </div>
        }
      </form>
    }
    @case ("PASSWORD_RESET_COMPLETE") {
      <h6 class="login-header">Password reset successfully</h6>
      <p>Please close this page and sign in again</p>
    }
  }

  <p class="login-footer text-caption">
    Need help?
    <a href="https://joinverify.com/knowledge-base/" target="_blank"
      >Visit our knowledge base</a
    >
  </p>
  <!-- <firebase-ui
    *ngIf="isTenantSet"
    (signInSuccessWithAuthResult)="successCallback()"
    (signInFailure)="errorCallback($event)"
    (uiShown)="uiShownCallback()"
  ></firebase-ui> -->
</div>
