import { BehaviorSubject } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import {
  Auth,
  AuthCredential,
  AuthProvider,
  confirmPasswordReset,
  fetchSignInMethodsForEmail,
  User as FireUser,
  getRedirectResult,
  linkWithCredential,
  PopupRedirectResolver,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  updateEmail,
  updatePassword,
  UserCredential,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import {
  CollectionName,
  FunctionName,
} from '@verify/shared-components/helpers';
import { Tenant, TenantInfo, User } from '@verify/shared-components/models';

import { Router } from '@angular/router';
import { FirestoreService } from './firestore.service';
import { FunctionService } from './function.service';

export const rootUser = 'ROOTUSER';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth: Auth = inject(Auth);
  private router = inject(Router);
  private firestoreService: FirestoreService = inject(FirestoreService);
  private functionService: FunctionService = inject(FunctionService);

  private static defaultTenant = 'dev1';

  private readonly _currentUser: BehaviorSubject<User | undefined> =
    new BehaviorSubject<User | undefined>(undefined);
  readonly currentUser$ = this._currentUser.asObservable();

  private readonly _tenant: BehaviorSubject<Tenant | undefined> =
    new BehaviorSubject<Tenant | undefined>(undefined);

  private readonly _tenantInfo: BehaviorSubject<TenantInfo | undefined> =
    new BehaviorSubject<TenantInfo | undefined>(undefined);

  readonly tenant$ = this._tenant.asObservable();
  readonly tenantInfo$ = this._tenantInfo.asObservable();

  constructor() {
    if (!this.auth.tenantId) {
      this.getTenantInfo();
    }
  }

  get currentUser(): User | undefined {
    return this._currentUser.value;
  }

  get tenant(): Tenant {
    return this._tenant.value;
  }

  get tenantInfo(): TenantInfo {
    return this._tenantInfo.value;
  }

  get tenantId(): string {
    return this.auth?.tenantId;
  }

  get isRootUser(): boolean {
    return this._tenant.value?.name === rootUser;
  }

  getAuth(): Auth {
    return this.auth;
  }

  getTenantName(): string {
    if (window.location.host.endsWith('.web.app')) {
      return rootUser;
    }
    const hostParts = window.location.host.split('.');
    return hostParts.length > 1
      ? hostParts[0].padEnd(4, '-')
      : AuthService.defaultTenant;
  }

  updateEmail(newEmail: string): Promise<void> {
    return updateEmail(this.auth.currentUser, newEmail);
  }

  updatePassword(newPassword: string): Promise<void> {
    return updatePassword(this.auth.currentUser, newPassword);
  }

  getRedirectResult(): Promise<UserCredential | null> {
    return getRedirectResult(this.auth);
  }

  signInWithEmailAndPassword(
    email: string,
    password: string,
  ): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  sendPasswordResetEmail(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  verifyPasswordResetCode(oobCode: string): Promise<string> {
    return verifyPasswordResetCode(this.auth, oobCode);
  }

  confirmPasswordReset(oobCode: string, password: string): Promise<void> {
    return confirmPasswordReset(this.auth, oobCode, password);
  }

  fetchSignInMethodsForEmail(email: string): Promise<string[]> {
    return fetchSignInMethodsForEmail(this.auth, email);
  }

  linkWithCredential(
    user: FireUser,
    credential: AuthCredential,
  ): Promise<UserCredential> {
    return linkWithCredential(user, credential);
  }

  signInWithRedirect(
    provider: AuthProvider,
    resolver?: PopupRedirectResolver,
  ): Promise<void> {
    return signInWithRedirect(this.auth, provider, resolver);
  }

  private findUser(userUid: string): void {
    this.firestoreService
      .getDocument<User>(`${CollectionName.users}/${userUid}`)
      .subscribe((user) => {
        this._currentUser.next(user || undefined);
      });
  }

  private getTenantInfo(): void {
    const tenantName = this.getTenantName();
    if (tenantName === rootUser) {
      console.log(`Tenant set to ${rootUser}`);
      this._tenant.next({ name: rootUser } as Tenant);
    } else {
      this.functionService
        .call<
          { tenantName: string },
          TenantInfo
        >(FunctionName.getTenantInfo, { tenantName })
        .subscribe((tenantInfo) => {
          if (tenantInfo) {
            const tenantId = tenantInfo.tenantId;
            this.auth.tenantId = tenantId;
            this._tenantInfo.next(tenantInfo || undefined);
            console.log(`Tenant set to ${tenantId}`);

            this.auth.onAuthStateChanged((user) => {
              if (user) {
                if (user.tenantId !== tenantId) {
                  this.auth.signOut().then(() => {
                    this.router.navigateByUrl('/login');
                  });
                } else {
                  this.firestoreService
                    .getDocument<Tenant>('')
                    .subscribe((tenant) => {
                      this._tenant.next(tenant || undefined);
                    });

                  this.findUser(user.uid);
                }
              }
            });
          }
        });
    }
  }
}
