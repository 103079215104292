export enum StoragePath {
  projects = 'projects',
  assets = 'assets',
  models = 'models',
  banner = 'banner',
  users = 'users',
  logo = 'logo',
  loginBackground = 'login-background',
  logoSmall = 'logo-small',
  tmp = 'tmp',
}
