import { Timestamp } from '@angular/fire/firestore';
import { TenantFeature } from './tenant.model';

export enum UserRule {
  userManagement = 'USER_MANAGEMENT', //User can view and edit all users
  tagManagement = 'TAG_MANAGEMENT', //User can view and edit all tags
  assetUploading = 'ASSET_UPLOADING', //User can upload new assets
  assetEditing = 'ASSET_EDITING', //User can edit assets
  assetViewing = 'ASSET_VIEWING', //User can view assets
  projectAdmin = 'PROJECT_ADMIN', // Gives user access to all projects
  projectManagement = 'PROJECT_MANAGEMENT', // User can edit and add new projects
  projectEditing = 'PROJECT_EDITING', // Allow model tagging, asset verifying
  projectUploading = 'PROJECT_UPLOADING', // User can upload new assets
  projectDownloading = 'PROJECT_DOWNLOADING', // User can download assets
  projectExport = 'PROJECT_EXPORT', // User can approve and download/export assets
}

export const userRuleGroups = [
  {
    name: 'SETTINGS',
    rules: [UserRule.userManagement],
  },
  {
    name: 'DAM',
    requiredFeature: TenantFeature.dam,
    rules: [
      UserRule.assetViewing,
      UserRule.assetUploading,
      UserRule.assetEditing,
    ],
  },
  {
    name: 'CONSENT_MANAGER',
    requiredFeature: TenantFeature.consentManager,
    rules: [
      UserRule.projectAdmin,
      UserRule.projectManagement,
      UserRule.projectEditing,
      UserRule.projectUploading,
      UserRule.projectDownloading,
      UserRule.projectExport,
    ],
  },
];

export interface UserRole {
  id: string;
  name: string;
  userIds: string[];
  rules: UserRule[];
  creationDate: Timestamp;
  modificationDate: Timestamp;
}
