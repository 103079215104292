import { ClientTimestamp } from '../shared-types';

export enum EmbeddingType {
  asset = 'ASSET',
}

export interface Embedding {
  type: EmbeddingType;
  creationDate?: ClientTimestamp;
  vector: number[];
}
