<h1 mat-dialog-title>
  {{ (dialogData?.user ? "base.edit" : "base.add") | translate }}
  {{ "user.user" | translate }}
</h1>

<mat-dialog-content>
  <ng-container *ngIf="!loading; else loadingTemplate">
    <div class="form" [formGroup]="form">
      <mat-form-field>
        <mat-label>{{ "user.first-name" | translate }}</mat-label>
        <input matInput type="text" formControlName="firstName" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "user.last-name" | translate }}</mat-label>
        <input matInput type="text" formControlName="lastName" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "user.email" | translate }}</mat-label>
        <input matInput type="text" formControlName="email" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "user.language" | translate }}</mat-label>
        <mat-select formControlName="language">
          <mat-option *ngFor="let language of languages" [value]="language">{{
            language
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "user-role.user-role" | translate }}</mat-label>
        <mat-select formControlName="roleId">
          <mat-option
            *ngFor="let userRole of userRoles$ | async"
            [value]="userRole.id"
            >{{ userRole.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="!dialogData?.user">
        <mat-label>{{ "user.invite-text" | translate }}</mat-label>
        <textarea matInput formControlName="inviteText" rows="4"></textarea>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-template #loadingTemplate>
    <lib-spinner class="spinner"></lib-spinner>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="secondary" (click)="onClose()">
    {{ "base.cancel" | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onSave()"
    [disabled]="form.invalid || loading"
  >
    {{ (dialogData?.user ? "base.save" : "base.add") | translate }}
  </button>
</mat-dialog-actions>
