import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { UserRule } from '@verify/shared-components/models';
import { AuthService } from '../auth.service';

export const hasRuleGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const authService: AuthService = inject(AuthService);
  const rules = route.data['rules'];

  let rulesRequired: UserRule[];
  if (!Array.isArray(rules)) {
    rulesRequired = [rules];
  } else {
    rulesRequired = rules;
  }
  for (const rule of rulesRequired) {
    if (!authService.currentUser?.rules?.includes(rule)) {
      return false;
    }
  }
  return true;
};
