import { inject, Injectable } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { CollectionName } from '@verify/shared-components/helpers';
import { UserGroup } from '@verify/shared-components/models';
import { FirestoreService } from '@verify/shared-components/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserGroupService {
  private firestore = inject(FirestoreService);

  constructor() {}

  getUserGroups(): Observable<UserGroup[]> {
    return this.firestore.getCollection<UserGroup>(CollectionName.userGroups);
  }

  addUserGroup(userGroup: Partial<UserGroup>): Observable<UserGroup> {
    return this.firestore.addDocument<UserGroup>(CollectionName.userGroups, {
      ...userGroup,
      creationDate: Timestamp.now(),
      modificationDate: Timestamp.now(),
    });
  }

  updateUserGroup(userGroup: UserGroup): Observable<void> {
    return this.firestore.updateDocument<UserGroup>(
      `${CollectionName.userGroups}/${userGroup.id}`,
      { ...userGroup, modificationDate: Timestamp.now() },
    );
  }

  deleteUserGroup(userGroup: UserGroup): Observable<void> {
    return this.firestore.deleteDocument(
      `${CollectionName.userGroups}/${userGroup.id}`,
    );
  }
}
