import { inject, Pipe, PipeTransform } from '@angular/core';

import { TenantFeature } from '@verify/shared-components/models';
import { AuthService } from '../auth.service';

/**
 * Returns a boolean indicating whether given
 * feature is enabled for this tenant
 *
 * Usage:
 *  'feature-name' | hasFeature
 */
@Pipe({
  name: 'hasFeature',
  standalone: true,
})
export class HasFeaturePipe implements PipeTransform {
  private authService: AuthService = inject(AuthService);

  transform(value: TenantFeature): boolean {
    return (this.authService.tenantInfo?.features || []).includes(value);
  }
}
