import { inject, Injectable } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { CollectionName } from '@verify/shared-components/helpers';
import { UserRole } from '@verify/shared-components/models';
import {
  BatchActionType,
  FirestoreService,
} from '@verify/shared-components/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  private firestore = inject(FirestoreService);

  constructor() {}

  getUserRoles(): Observable<UserRole[]> {
    return this.firestore.getCollection<UserRole>(CollectionName.userRoles);
  }

  addUserRole(userRole: Partial<UserRole>): Observable<UserRole> {
    return this.firestore.addDocument<UserRole>(CollectionName.userRoles, {
      ...userRole,
      creationDate: Timestamp.now(),
      modificationDate: Timestamp.now(),
    });
  }

  updateUserRole(userRole: UserRole): Observable<void> {
    return this.firestore.batchWrite([
      {
        documentPath: `${CollectionName.userRoles}/${userRole.id}`,
        data: { ...userRole, modificationDate: Timestamp.now() },
        type: BatchActionType.update,
      },
      ...(userRole.userIds
        ? userRole.userIds.map((userId) => ({
            documentPath: `${CollectionName.users}/${userId}`,
            data: { rules: userRole.rules },
            type: BatchActionType.update,
          }))
        : []),
    ]);
  }

  deleteUserRole(userRole: UserRole): Observable<void> {
    return this.firestore.deleteDocument(
      `${CollectionName.userRoles}/${userRole.id}`,
    );
  }
}
