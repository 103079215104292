import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { User, UserRole } from '@verify/shared-components/models';

import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '@verify/shared-components/components';
import { Observable, take } from 'rxjs';
import { UserRoleService, UserService } from '../../../../services';

@Component({
  selector: 'app-edit-user-dialog',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    SpinnerComponent,
    TranslateModule,
  ],
  templateUrl: './edit-user-dialog.component.html',
  styleUrl: './edit-user-dialog.component.scss',
})
export class EditUserDialogComponent {
  private dialogRef = inject(MatDialogRef<string | boolean>);
  private formBuilder = inject(FormBuilder);
  private userService = inject(UserService);
  private userRoleService = inject(UserRoleService);

  dialogData: { user?: User; basicInvite?: boolean } = inject(DIALOG_DATA);

  readonly form = this.formBuilder.nonNullable.group({
    firstName: [''],
    lastName: [''],
    email: ['', [Validators.required, Validators.email]],
    language: ['EN'],
    roleId: [null],
    inviteText: [''],
  });

  languages = ['EN', 'NL'];
  loading = false;
  userRoles$: Observable<UserRole[]>;

  constructor() {
    if (this.dialogData?.user) {
      this.form.patchValue(this.dialogData.user);
    }
    this.userRoles$ = this.userRoleService.getUserRoles();
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  onSave(): void {
    this.userRoles$.pipe(take(1)).subscribe((userRoles) => {
      this.loading = true;
      const { firstName, lastName, email, language, roleId, inviteText } =
        this.form.value;
      const userRole = userRoles.find((r) => r.id === roleId);
      if (this.dialogData?.user) {
        const user = {
          ...this.dialogData.user,
          firstName,
          lastName,
          email,
          language,
          roleId,
          rules: userRole ? userRole.rules : [],
        };
        this.userService.updateUser(user).subscribe(() => {
          this.loading = false;
          this.dialogRef.close(user.id);
        });
      } else {
        this.userService
          .addUser(
            {
              firstName,
              lastName,
              email,
              language,
              roleId,
              rules: userRole ? userRole.rules : [],
            },
            inviteText,
          )
          .subscribe((response) => {
            this.loading = false;
            this.dialogRef.close(response.userId);
          });
      }
    });
  }
}
